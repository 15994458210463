var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"1000px","persistent":"","scrollable":""},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.model.id ? 'Edición' : 'Creación')+" Lugar")])]),_c('v-card-text',[_c('validation-observer',{ref:"form"},[_c('div',{staticClass:"row mt-1"},[_c('div',{staticClass:"col-12 col-md-4 py-0"},[_c('validation-provider',{attrs:{"name":"Nombre","vid":"name","rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Nombre","required":""},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4 py-0"},[_c('validation-provider',{attrs:{"name":"Área","vid":"area","rules":"required|max:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","outlined":"","dense":"","error-messages":errors,"label":"Área","required":""},model:{value:(_vm.model.area),callback:function ($$v) {_vm.$set(_vm.model, "area", $$v)},expression:"model.area"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4 py-0"},[_c('validation-provider',{attrs:{"name":"Color","vid":"color","rules":"required|max:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","error-messages":errors,"label":"Color","required":""},model:{value:(_vm.model.color),callback:function ($$v) {_vm.$set(_vm.model, "color", $$v)},expression:"model.color"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4 py-0"},[_c('validation-provider',{attrs:{"name":"Tamaño","vid":"size","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","outlined":"","dense":"","error-messages":errors,"label":"Tamaño","required":""},model:{value:(_vm.model.size),callback:function ($$v) {_vm.$set(_vm.model, "size", $$v)},expression:"model.size"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4 py-0"},[_c('validation-provider',{attrs:{"name":"Ancho imagen","vid":"planeWidth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","outlined":"","dense":"","error-messages":errors,"label":"Ancho imagen (Debe ser 1200px)","required":""},model:{value:(_vm.model.planeWidth),callback:function ($$v) {_vm.$set(_vm.model, "planeWidth", $$v)},expression:"model.planeWidth"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4 py-0"},[_c('validation-provider',{attrs:{"name":"Alto imagen","vid":"planeHeight","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","outlined":"","dense":"","error-messages":errors,"label":"Alto imagen","required":""},model:{value:(_vm.model.planeHeight),callback:function ($$v) {_vm.$set(_vm.model, "planeHeight", $$v)},expression:"model.planeHeight"}})]}}])})],1),_c('div',{staticClass:"col-12 py-0"},[_c('validation-provider',{attrs:{"vid":"description","name":"Descripción","rules":"min:0|max:600"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"counter":"600","outlined":"","rows":"2","type":"text","error-messages":errors,"clear-icon":"mdi-close-circle","label":"Escribe una breve descripción de la feria"},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}})]}}])})],1),_c('div',{staticClass:"col-12 col-md-4 py-0"},[_c('h3',[_vm._v("Con cuadrícula")]),_c('file-chooser',{ref:"fileChooser",attrs:{"options":{
                  height: '190px',
                  description: 'Haz clic para cargar el plano del lugar con cuadrícula',
                  regex: /\.(jpg|jpeg|png|gif)$/,
                  error: 'Solo se permiten imagenes',
                  fileSize: 2,
                }},on:{"input":_vm.onLoadAdminPlaneImageUrl}})],1),_c('div',{staticClass:"col-12 col-md-4 py-0"},[_c('h3',[_vm._v("Sin cuadrícula")]),_c('file-chooser',{ref:"fileChooser2",attrs:{"options":{
                  height: '190px',
                  description: 'Haz clic para cargar el plano del lugar sin cuadrícula',
                  regex: /\.(jpg|jpeg|png|gif)$/,
                  error: 'Solo se permiten imagenes',
                  fileSize: 2,
                }},on:{"input":_vm.onLoadUserPlaneImageUrl}})],1),_c('div',{staticClass:"col-12 col-md-4 py-0"},[_c('h3',[_vm._v("Con stands para App")]),_c('file-chooser',{ref:"fileChooser3",attrs:{"options":{
                  height: '190px',
                  description: 'Haz clic para cargar el plano general del lugar con stands para App',
                  regex: /\.(jpg|jpeg|png|gif)$/,
                  error: 'Solo se permiten imagenes',
                  fileSize: 2,
                }},on:{"input":_vm.onLoadAppPlaneImageUrl}})],1)])])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.savePlace}},[_vm._v(" Guardar ")]),(_vm.model.id)?_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.manageStands}},[_vm._v(" Administrar Stands ")]):_vm._e(),(_vm.model.id)?_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.deletePlace}},[_vm._v(" Eliminar ")]):_vm._e(),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }